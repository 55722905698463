//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
}
a {
  text-decoration: none;
}
.westernchat-header {
  background-color: $western-purple;
  padding: 1em;
}
.western-chat-logo{
  height: 40px;
}
.western-header-logo {
  height:60px;
}
.western-footer-logo{
  height: 50px;
}
.western-purple {
  color: $western-purple;
}
.sorry-gradient {
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(91,82,162,1) 100%);
  color: $light;
}
.closure-gradient {
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(229,108,37,1) 100%);
  color: $light;
}
.hi-there-gradient {
  background: rgb(246,146,34);
  background: linear-gradient(90deg, rgba(246,146,34,1) 0%, rgba(253,202,15,1) 100%);
}
.login-gradient{
  background: rgb(120,187,60);
background-image: linear-gradient(90deg, rgba(120,187,60,1) 0%, rgba(210,230,165,1) 100%);
border: none;
font-family: $font-medium;
}
.login-gradient:hover{
  background: rgb(120,187,60);
}

.prospective-gradient{
  background: rgb(198,25,139);
background: linear-gradient(90deg, rgba(198,25,139,1) 0%, rgba(212,114,173,1) 100%); 
border: none;
font-family: $font-medium;
}
.prospective-gradient:hover {
  color: #ffffff;
  background: rgb(198,25,139);
  border: none;
}
.other-button{
  /*background: rgb(198,25,139);
background: linear-gradient(90deg, rgba(198,25,139,1) 0%, rgba(212,114,173,1) 100%); */
border: 1px solid #000000;
font-family: $font-medium;
color: #000000;
}
.other-button:hover {
  color: #ffffff;
  background-color: #000000;
  border: 1px solid #000000;
}
.hello-gradient {
  background: rgb(13,148,219);
background: linear-gradient(90deg, rgba(13,148,219,1) 0%, rgba(53,196,243,1) 100%);
}
.need-help{
  font-size:.5em;
  font-family: franklin-gothic-atf, sans-serif;
  font-weight: 100;
  text-decoration: none;
  color: $primary;
}
.grad-help{
  font-size:.5em;
  font-family: franklin-gothic-atf, sans-serif;
  font-weight: 100;
  text-decoration: none;
}
.night-icon {
  color: $night-icon;
}
.closure-icon {
  color: #8f6f60;
}
.sun-icon {
  color: #ee5427;
}
.comment-icon {
  color: #0e6dba;
}
.logged-in-type {
  font-size:.7em;
  font-family: $font-book;
  font-weight: 100;
  text-decoration: none;
  color: #ffffff;
}
.select-options {
  font-family: $font-book;
}
.logged-in-info {
  font-family: $font-light;
  font-size:0.6em;
}
.mobile-chat-button{
  border-radius: 0;
  font-size:2em;
}
.mobile-chat{
  height: 100px;
}
.sorry-text {
  font-size: 2.2em;
  font-weight: 100;
  font-family: franklin-gothic-atf, sans-serif;
  font-weight: 100;
  font-style: normal;
}
/*.hi-there-text{
  font-size: 2.2em;
  font-weight: 100;
  font-family: franklin-gothic-atf, sans-serif;
  font-weight: 100;
  font-style: normal;
  color: #ffffff;
}*/
.hi-there-text{
  font-size: 2.2em;
  font-weight: 100;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #ffffff;
}

.login-form{
  font-size: 1em;
  font-weight: 100;
  font-family: $font-light;
  color: #ffffff;
}
.out-of-hours {
font-family: $font-medium;
font-size:1em;
font-weight: 400;
font-style: normal;
color: $western-purple;
}
.button-choice {
  font-family: $font-normal;
font-size:1.2em;
font-weight: 100;
font-style: normal;
}
/*.out-of-hours-message {
  font-size: 1.6em;
  font-weight: 100;
  font-family: franklin-gothic-atf, sans-serif;
  font-weight: 100;
  font-style: normal;
}*/

.out-of-hours-message {
  font-size: 1.7em;
  font-weight: 100;
  font-family: franklin-gothic-urw, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.chat-hours {
  font-family: $font-medium;
  font-size:.8em;
  font-style: normal;
}
.night-footer {
 background-color: #000000;
}
.footer-logo {
  height: 50px;
}
.footer-copy {
  color: #ffffff;
  font-size: .6em;
  font-weight: 100;
  font-family: franklin-gothic-atf, sans-serif;
  font-weight: 100;
  font-style: normal;
}
//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";
/* bold */
@font-face {
    font-family: 'NovecentowideBookBold';
    src: url('./fonts/Novecentowide-Bold-webfont.eot');
    src: url('./fonts/Novecentowide-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/Novecentowide-Bold-webfont.woff') format('woff'),
         url('./fonts/Novecentowide-Bold-webfont.ttf') format('truetype'),
         url('./fonts/Novecentowide-Bold-webfont.svg#fonts/NovecentowideBookBold') format('svg');
    font-weight: normal;
    font-style: normal;

}

/* book */
@font-face {
    font-family: 'NovecentowideBookRegular';
    src: url('./fonts/Novecentowide-Book-webfont.eot');
    src: url('./fonts/Novecentowide-Book-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/Novecentowide-Book-webfont.woff') format('woff'),
         url('./fonts/Novecentowide-Book-webfont.ttf') format('truetype'),
         url('./fonts/Novecentowide-Book-webfont.svg#NovecentowideBookRegular') format('svg');
    font-weight: normal;
    font-style: normal;

}

/* Demi bold */
@font-face {
    font-family: 'NovecentowideLightBold';
    src: url('./fonts/Novecentowide-DemiBold-webfont.eot');
    src: url('./fonts/Novecentowide-DemiBold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/Novecentowide-DemiBold-webfont.woff') format('woff'),
         url('./fonts/Novecentowide-DemiBold-webfont.ttf') format('truetype'),
         url('./fonts/Novecentowide-DemiBold-webfont.svg#NovecentowideLightBold') format('svg');
    font-weight: normal;
    font-style: normal;

}

/* light */
@font-face {
    font-family: 'NovecentowideLightRegular';
    src: url('./fonts/Novecentowide-Light-webfont.eot');
    src: url('./fonts/Novecentowide-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/Novecentowide-Light-webfont.woff') format('woff'),
         url('./fonts/Novecentowide-Light-webfont.ttf') format('truetype'),
         url('./fonts/Novecentowide-Light-webfont.svg#NovecentowideLightRegular') format('svg');
    font-weight: normal;
    font-style: normal;

}
/* medium */
@font-face {
    font-family: 'NovecentowideUltraLightBold';
    src: url('./fonts/Novecentowide-Medium-webfont.eot');
    src: url('./fonts/Novecentowide-Medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/Novecentowide-Medium-webfont.woff') format('woff'),
         url('./fonts/Novecentowide-Medium-webfont.ttf') format('truetype'),
         url('./fonts/Novecentowide-Medium-webfont.svg#NovecentowideUltraLightBold') format('svg');
    font-weight: normal;
    font-style: normal;

}

/* normal */
@font-face {
    font-family: 'NovecentowideNormalRegular';
    src: url('./fonts/Novecentowide-Normal-webfont.eot');
    src: url('./fonts/Novecentowide-Normal-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/Novecentowide-Normal-webfont.woff') format('woff'),
         url('./fonts/Novecentowide-Normal-webfont.ttf') format('truetype'),
         url('./fonts/Novecentowide-Normal-webfont.svg#NovecentowideNormalRegular') format('svg');
    font-weight: normal;
    font-style: normal;

}

